<template>
  <BaseContainer fluid class="max-w-1024">
    <BaseCard class="p-3 pb-0">
      <BaseRow v-if="!isLoading && course">
        <BaseCol cols="12">
          <CourseDetails :course="course" />
        </BaseCol>

        <BaseCol cols="12">
          <BaseRow class="flex align-items-center">
            <BaseCol cols="12" md="6" class="mt-3">
              <ChaptersCompletedLabel :course="course" />
            </BaseCol>

            <BaseCol cols="12" md="6" class="mt-3 flex lg:justify-content-end">
              <CourseContinueButton :course-id="course.id" />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>

      <BaseRow v-else>
        <BaseCol cols="12" class="text-center">
          <BaseProgressSpinner />
        </BaseCol>
      </BaseRow>
    </BaseCard>
  </BaseContainer>
</template>

<script>
import { useRoute } from "vue-router";
import useApi from "@use/useApi";
import { computed, watch, ref } from "vue";
import { getTeacherName } from "@/modules/user-course-uc/helper/GetTeacherName";
import CourseContinueButton from "./partials/CourseContinueButton";
import ChaptersCompletedLabel from "../../../../components/ChaptersCompletedLabel";
import CourseDetails from "@/modules/user-course-uc/components/CourseDetails";

export default {
  components: {
    CourseDetails,
    ChaptersCompletedLabel,
    CourseContinueButton,
  },
  setup() {
    const route = useRoute();

    const course = ref();
    const courseId = computed(() => {
      return route.params.courseId;
    });

    const { callApi, isLoading, docs } = useApi({
      module: "uc",
      method: "showCourse",
      onMount: true,
      params: {
        courseId: courseId.value,
      },
      onSuccess: () => {
        course.value = docs.value.data;
      },
    });

    watch(courseId, () => {
      if (courseId.value) {
        callApi({ courseId: courseId.value });
      }
    });

    return {
      isLoading,
      course,
      getTeacherName,
    };
  },
};
</script>
