<template>
  <BaseButton label="uc.my.courses.show.start" :loading="isLoading" @click="pushToChapter" />
</template>

<script>
import { ref } from "vue";
import useApi from "@use/useApi";
import COURSE_CHAPTER_PROGRESSION_STATUS from "@/modules/user-course-uc/constants/COURSE_CHAPTER_PROGRESSION_STATUS";
import { useRouter } from "vue-router";

export default {
  props: {
    courseId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();

    const chapters = ref(null);
    const unusedChapterId = ref(null);

    const setUnusedChapterId = () => {
      const firstUncompletedChapter = Object.values(chapters.value).find((item) => {
        return (
          item.courseChapterProgression.courseChapterProgressionStatusId !==
          COURSE_CHAPTER_PROGRESSION_STATUS.DONE
        );
      });

      unusedChapterId.value = firstUncompletedChapter ? firstUncompletedChapter.id : null;
    };

    const { docs, isLoading } = useApi({
      module: "uc",
      method: "listCourseChapters",
      onMount: true,
      params: {
        courseId: props.courseId,
      },
      onSuccess: () => {
        chapters.value = docs.value.data;
        setUnusedChapterId();
      },
    });

    const pushToChapter = async () => {
      try {
        await router.push({
          name: "MyCoursesShowChapter",
          params: {
            courseChapterId: unusedChapterId.value || chapters.value[0].id,
          },
        });
      } catch {
        return;
      }
    };

    return {
      unusedChapterId,
      isLoading,
      pushToChapter,
    };
  },
};
</script>
